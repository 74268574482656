import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
export const headers: DataQualityHeader[] = [
  {
    text: "Vessel",
    type: "string",
    align: "left",
    value: "vesselName",
    width: "260px",
  },
  {
    text: "Overall Status",
    type: "dataIndicator",
    class: "overall-column",
    align: "left",
    value: "overallStatus",
    tooltip: "Indicates the overall status of outliers found of each vessel.",
  },
  {
    text: "STW",
    type: "percentIndicator",
    class: "text-center",
    align: "center",
    value: "stw",
    width: "140px",
  },
  {
    text: "Draft",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "draft",
    width: "140px",
  },
  {
    text: "SP#1",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "speedLog",
    width: "140px",
  },
  {
    text: "SP#2",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "gefoc1",
    width: "140px",
  },
  {
    text: "ME #1FOC",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "speedLog",
    width: "140px",
  },
  {
    text: "ME #2FOC",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "speedLog",
    width: "140px",
  },
  {
    text: "G/E #1FOC",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "speedLog",
    width: "140px",
  },
  {
    text: "G/E #2FOC",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "speedLog",
    width: "140px",
  },
  {
    text: "G/E #3FOC",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "speedLog",
    width: "140px",
  },
  {
    text: "G/E #4FOC",
    type: "percentIndicator",
    align: "center",
    class: "text-center",
    value: "speedLog",
    width: "140px",
  },
];
