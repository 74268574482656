






























  import { Component, PropSync, Vue } from "vue-property-decorator";
  import DataOutliersModalTable from "./DataOutliersModalTable.vue";
  import DataOutliersModalFilter from "./DataOutliersModalFilter.vue";
  import DataOutliersModalFooter from "./DataOutliersModalFooter.vue";
  import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
  import { DataOutliersViewHistoryRow } from "@/types/dataQuality/dataOutliersTable";
  import { getModule } from "vuex-module-decorators";
  import VesselsModule from "@/store/clients/Vessels.module";
  import store from "@/store";
  import DataQualityModule from "@/store/clients/DataQuality.module";
  import { DataOutliersViewHistoryType } from "@/types/dataQuality/dataOutliers";

  const Vessels = getModule(VesselsModule, store);
  const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataOutliersModalTable,
    DataOutliersModalFilter,
    DataOutliersModalFooter,
  },
})
  export default class DataOutliersViewHistoryModal extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;
  selectedRows: DataOutliersViewHistoryRow[] = [];
  selectedColumn = "";
  searchTerm = "";

  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshDataOutliersViewHistory()]);
  }

  get modalTableLoading(): boolean {
    return DataQuality.modalLoadingState;
  }

  get filteredTags(): any {
    if (this.selectedColumn === null || this.selectedColumn === "") {
      return this.dataOutliersViewHistoryRow;
    }
    if (this.searchTerm === null || this.searchTerm === "") {
      return this.dataOutliersViewHistoryRow;
    }
    const headerKey = this.tagTableHeaders.find((header) => header.text === this.selectedColumn)!.value;
    return this.dataOutliersViewHistoryRow.filter((item) => {
      const value = item[headerKey];
      if (typeof value === "string") {
        return value.toLowerCase().includes(this.searchTerm.toLowerCase());
      }
      if (typeof value === "number") {
        return value.toString().includes(this.searchTerm);
      }
      return false;
    });
  }

  get warningMessage(): string {
    if ((this.searchTerm == "" || this.searchTerm == null || this.searchTerm == undefined) && !(this.selectedColumn == null || this.selectedColumn == "")) {
      return "Please enter a search term";
    }
    if ((this.selectedColumn == "" || this.selectedColumn == null || this.selectedColumn == undefined) && !(this.searchTerm == null || this.searchTerm == "")) {
      return "Please select a row to filter by";
    }
    return "";
  }

  get isDisabled(): boolean {
    return this.selectedRows.length === 0;
  }

  submitUndoSelectedActions(): void {
    var selectedUndoActions: DataOutliersViewHistoryType[] = this.selectedRows.map((item) => ({
      vesselId: Vessels.extendedVessels.find(vessel => vessel.name === item.name)!.id,
      timestamp: new Date(item.timestamp),
      tag: item.tag,
      value: item.value,
      reasonCode: item.reasonCode,
      actionTaken: item.actionTaken,
      dateOfAction: new Date(item.dateOfAction),
      userWhoActed: item.userWhoActed,
    }));
    DataQuality.updateDataOutliersViewHistory(selectedUndoActions);
    this.closeModal();
  }

  closeModal(): void {
    this.selectedRows = [];
    this.selectedColumn = "";
    this.searchTerm = "";
    this.dialog = false;
  }

  tagTableHeaders: DataQualityHeader[] = [
  {
      text: "Vessel",
      type: "string",
      align: "start",
      value: "name",
    },
    { text: "Timestamp", type: "string", value: "timestamp" },
    { text: "Tag", type: "string", value: "tag" },
    { text: "Value", type: "string", value: "value" },
    { text: "Reason Code", type: "string", value: "reasonCode" },
    { text: "Action Taken", type: "string", value: "actionTaken" },
    { text: "Date of Action", type: "string", value: "dateOfAction" },
    { text: "User Who Acted", type: "string", value: "userWhoActed" },
  ];

  get dataOutliersViewHistoryRow(): DataOutliersViewHistoryRow[] {
    var dataOutliers = DataQuality.dataOutliersViewHistory.map((item, index) => {
    const vessel = Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId);
    return {
      key: index,
      name: vessel ? vessel.name : "Unknown Vessel",
      timestamp: new Date(item.timestamp).toLocaleString(),
      tag: item.tag,
      value: item.value,
      reasonCode: item.reasonCode,
      actionTaken: item.actionTaken,
      dateOfAction: new Date(item.dateOfAction).toLocaleDateString(),
      userWhoActed: item.userWhoActed,
    };
  });
    return dataOutliers;
  }
  }
