










































import { Component, Prop, Vue } from "vue-property-decorator";
//  utilities
import dateHelper from "Utilities/date-helper";
import moment from "moment";
//  types
import { IndicatorData } from "@/types/dataQuality/dataQualityTable";

@Component({})
export default class DataIndicator extends Vue {
  @Prop({ type: Object }) data!: IndicatorData;

  //  @Getters
  get isNotAvailable(): boolean {
    return this.data.status === 0;
  }

  get statusColor() {
    if (!this.data) return;
    switch (this.data.status) {
      case 1:
        return "success100";
      case 2:
        return "warning100";
      case 3:
        return "error100";
      default:
        return "darkGrey100";
    }
  }

  get outlierValueClasses() {
    if (!this.data) return;
    if (Number(this.data.text) <= 10) return "minor";
    return "major";
  }

  //  @Methods
  navigateToLink(): void {
    if (!this.data.link) throw new Error("Link is undefined");
    this.$router.push(this.data.link);
  }

  getFormatedDateTimeString(timestamp: string): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  duration(timestamp: string): string {
    return dateHelper.getHumanizeDuration(timestamp, moment().valueOf());
  }
}
