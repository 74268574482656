













  import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
  import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component
  export default class DataOutliersModalFilter extends Vue {
  @PropSync("selected", { type: String, default: "" }) selectedItem!: string;
  @PropSync("search", { type: String }) searchTerm!: string | null;

  @Prop({ type: Array, default: () => [] }) selection!: DataQualityHeader[];

  get selectionWithNone(): string[] {
    return this.selection.map(header => header.text);
  }
  }
