

















  import { Component, Prop, Vue } from "vue-property-decorator";

@Component
  export default class DataOutliersModalFooter extends Vue {
  @Prop({ type: String, default: "" }) displayedMessage!: string;
  @Prop({ type: Boolean, default: true }) isDisabled!: boolean;
  @Prop({ type: String, default: "" }) textBlueButton?: string;
  @Prop({ type: String, default: "" }) textRedButton?: string;

  }

