

















































import { Vue, Component } from "vue-property-decorator";
import store from "@/store";
//  types
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
import { headers } from "./VDataTableHeaders/DataOutliers";
//  components
import DataOutliersTakeActionModal from "@/components/dataQuality/dataOutliersModals/DataOutliersTakeActionModal.vue";
import DataOutliersViewHistoryModal from "@/components/dataQuality/dataOutliersModals/DataOutliersViewHistoryModal.vue";
import DataOutliersTable from "@/components/dataQuality/DataOutliersTable/index.vue";

//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataOutliersTable,
    DataOutliersTakeActionModal,
    DataOutliersViewHistoryModal,
  },
})
export default class DataOutliers extends Vue {
  searchQuery = "";
  takeActionDialog = false;
  viewHistoryDialog = false;
  headers: DataQualityHeader[] = headers;

  // @GETTERS
  get isEnvDevelopment(): boolean {
    return process.env.NODE_ENV === "development";
  }

  get dataOutliers(): any {
    var dataOutliers = DataQuality.dataOutliers.map((item, index) => ({
      key: index,
      vesselName: {
        value: Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId)?.name ?? "Uknown Vessel",
        link: null,
      },
      overallStatus: {
        value: item.overallStatus,
        link: null,
      },
      stw: {
        value: item.data.find(row => row.featureName === "STW")?.value,
        link: null,
      },
      draft: {
        value: item.data.find(row => row.featureName === "Draft")?.value,
        link: null,
      },
      speedLog: {
        value: item.data.find(row => row.featureName === "speedLog")?.value,
        link: null,
      },
      gefoc1: {
        value: item.data.find(row => row.featureName === "gefoc1")?.value,
        link: null,
      },
      gefoc2: {
        value: item.data.find(row => row.featureName === "gefoc2")?.value,
        link: null,
      },
    }));
    return dataOutliers;
  }
  // @METHODS
  // @HOOKS
  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshDataOutliers()]);
  }
}
